import React, { useState } from 'react';
import { Carousel, Modal, Button, Form } from 'react-bootstrap';

const Prices = () => {

const [show, setShow] = useState(false);
const [customerName, setCustomerName] = useState('');
const [businessName, setBusinessName] = useState('');
const [selectedPackage, setSelectedPackage] = useState('Silver Package'); // Default package

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const handleSubmit = () => {
  const message = `Name: ${customerName}\nBusiness Name: ${businessName}\nPackage: ${selectedPackage}\n`;
  const whatsappNumber =`https://wa.me/7410726107?text=${encodeURIComponent(message)}`; // Update with your WhatsApp link

  window.location.href = whatsappNumber; // Redirect to WhatsApp
  handleClose(); // Close the modal after sending the message
};


  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
        <h5 className="fw-bold text-primary text-uppercase">Pricing Plans</h5>
        <h1 className="mb-0">We are Offering Competitive Prices for Our Clients</h1>
      </div>
      <div className="row g-0">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="bg-light rounded">
            <div className="border-bottom py-4 px-5 mb-4">
              <h4 className="text-primary mb-1">Silver Package</h4>
              <small >For Small Size Business</small>
            </div>
            <div className="p-5 pt-0">
              {/* <h1 className="display-5 mb-3">
                <small className="align-top" style={{ fontSize: '22px', lineHeight: '45px' }}>&#8377;</small>
                 12000
                <small className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Month</small>
              </h1> */}
              <div className="d-flex justify-content-between mb-3">
                <span>30 days Social Media Ads</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>15 Graphic Designs</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Ads Creation & Social Media management</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Content Creation</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span> Website Deigning</span>
                <i className="fa fa-times text-danger pt-1"></i>
              </div>
              <a className="btn btn-primary py-2 px-4 mt-4" onClick={handleShow} >Get Quotation</a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="bg-white rounded shadow position-relative" style={{ zIndex: 1 }}>
            <div className="border-bottom py-4 px-5 mb-4">
              <h4 className="text-primary mb-1">Diamond Package</h4>
              <small >For Large Size Business</small>
              <p >Free Google Review QR Code</p>
            </div>
            <div className="p-5 pt-0">
              {/* <h1 className="display-5 mb-3">
                <small className="align-top" style={{ fontSize: '22px', lineHeight: '45px' }}>&#8377;</small>
                45000
                <small className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Month</small>
              </h1> */}
              <div className="d-flex justify-content-between mb-3">
                <span>30 days Social Media Ads</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>15 Graphic Designs</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>3 Cinematic Reels</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Ads Creation & Social Media Management</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Content Creation</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Dynamic Website</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
            
              <a className="btn btn-primary py-2 px-4 mt-4" onClick={handleShow}>Get Quotation</a>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Get Quotation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Business Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your business name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Package</Form.Label>
            <Form.Control
              as="select"
              value={selectedPackage}
              onChange={(e) => setSelectedPackage(e.target.value)}
            >
              <option>Silver Package</option>
              <option>Gold Package</option>
              <option>Diamond Package</option>
              {/* Add more options as needed */}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Send Quotation
        </Button>
      </Modal.Footer>
    </Modal>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
          <div className="bg-light rounded">
            <div className="border-bottom py-4 px-5 mb-4">
              <h4 className="text-primary mb-1">Golden Package</h4>
              <small >For Medium Size Business</small>
            </div>
            <div className="p-5 pt-0">
              {/* <h1 className="display-5 mb-3">
                <small className="align-top" style={{ fontSize: '22px', lineHeight: '45px' }}>&#8377;</small>
                25000
                <small className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Month</small>
              </h1> */}
              <div className="d-flex justify-content-between mb-3">
                <span>30 days Social Media Ads</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>2 Cinematic Reels</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>15 Graphic Designs</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Ads Creation & Social Media Management</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Static 5 Page Website + Google Review QR Code</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Content Creation</span>
                <i className="fa fa-check text-primary pt-1"></i>
              </div>
              <a onClick={handleShow} className="btn btn-primary py-2 px-4 mt-4">Get Quotation</a>
            </div>
          </div>
        </div>
        <div class="elfsight-app-b3cca1af-f288-4c78-95f0-c683f207ea55" data-elfsight-app-lazy></div>
      </div>
    </div>
  </div>
  )
}

export default Prices