import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import bgvideo from './videos/Digital Marketing Website Background.mp4';
import './Services.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Services = () => {
  const [name, setName] = useState('');
  const [business, setBusiness] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    // Fetch testimonials from server
    const fetchTestimonials = async () => {
      try {
        const response = await fetch('https://api.shreedigimarketing.in/api/testimonials');
        if (response.ok) {
          const data = await response.json();
          setTestimonials(data);
        } else {
          console.error('Failed to fetch testimonials');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchTestimonials();
  }, []);
  console.log(testimonials);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    const whatsappMessage = `Name: ${name}, Business: ${business}, Service: ${service}, Message: ${message}`;
    const whatsappURL = `https://wa.me/7410726107?text=${encodeURIComponent(whatsappMessage)}`;

    window.location.href = whatsappURL; // Redirect to WhatsApp
  };

  return (
    <div>
      {/* Header Section */}
      <div className="container-fluid py-5 bg-header">
        <video className="background-video" autoPlay muted loop>
          <source src={bgvideo} type="video/mp4" />
        </video>
        <div className="overlay">
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Services</h1>
              <Link to="/" className="h5 text-white">Home</Link>
              <i className="far fa-circle text-white px-2"></i>
              <Link to="/services" className="h5 text-white">Services</Link>
            </div>
          </div>
        </div>
      </div>

      
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
          <h1 className="mb-0">Custom IT Solutions for Your Successful Business</h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.2s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <i className="fas fa-chart-line text-white"></i>
              </div>
              <h4 className="mb-3">Social Media Marketing</h4>
              <p className="m-0">Partner with a leading digital marketing agency to increase brand awareness, reach your target audience & grow your business online.</p>
              <a className="btn btn-lg btn-primary rounded" href="/contact">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <i className="fas fa-envelope-open text-white"></i>
              </div>
              <h4 className="mb-3">Email Marketing</h4>
              <p className="m-0">Reach out to potential customers through personalized email campaigns.</p>
              <a className="btn btn-lg btn-primary rounded" href="/contact">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.5s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <i className="fa fa-code text-white"></i>
              </div>
              <h4 className="mb-3">Website Designing</h4>
              <p className="m-0">Create visually appealing and user-friendly websites tailored to your business needs.</p>
              <a className="btn btn-lg btn-primary rounded" href="/contact">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.2s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <i className="fab fa-android text-white"></i>
              </div>
              <h4 className="mb-3">Apps Development</h4>
              <p className="m-0">Transform your business with our expert app development services, delivering custom, high-performance mobile solutions tailored to your needs</p>
              <a className="btn btn-lg btn-primary rounded" href="/contact">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
              <div className="service-icon">
                <i className="fa fa-search text-white"></i>
              </div>
              <h4 className="mb-3">SEO Optimization</h4>
              <p className="m-0">Work with a top search engine optimization company to achieve better search engine ranking, earn more traffic and generate greater revenue.</p>
              <a className="btn btn-lg btn-primary rounded" href="/contact">
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.5s">
            <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
              <h3 className="text-white mb-3">Call Us For Quote</h3>
              <p className="text-white mb-3">Shree Digital Marketing Agency specializes in 360-degree digital marketing, providing tailored solutions to help brands scale up.</p>
              <h2 className="text-white mb-0">+91 7410726107</h2>
            </div>
          </div>
        </div>
      </div>
    </div>


      {/* Testimonials Section */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: '800px' }}>
            <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
            <h1 className="mb-0">What Our Clients Say About Our Digital Services</h1>
          </div>
          <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-item bg-light my-4 mx-3">
                <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
  className="img-fluid rounded"
  src={`https://api.shreedigimarketing.in/${testimonial.imageurl}`}
  style={{ width: '8vw', height: '5vw',objectFit:'cover' }}
  alt={`Client ${index + 1}`}
/>

                  <div className="ps-4">
                    <h4 className="text-primary mb-1">{testimonial.clientname}</h4>
                    
                  </div>
                </div>
                <div className="pt-4 pb-5 px-5">
                <small className="text-uppercase">{testimonial.profession}</small><br></br>
                  {testimonial.testimonial}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

    <div>
    <script src="https://static.elfsight.com/platform/platform.js" async></script>
    <div class="elfsight-app-b3cca1af-f288-4c78-95f0-c683f207ea55" data-elfsight-app-lazy></div>
    </div>

      {/* Request a Quote Section */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">Request A Quote</h5>
                <h1 className="mb-0">Need A Free Quote? Please Feel Free to Contact Us</h1>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-4">
                    <i className="fa fa-reply text-primary me-3"></i>Reply within 24 hours
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-4">
                    <i className="fa fa-phone-alt text-primary me-3"></i>24 hrs telephone support
                  </h5>
                </div>
              </div>
              <p className="mb-4">
                Ready to elevate your digital presence? Simply provide your details, and our experts will craft a personalized strategy tailored to your needs. No strings attached, just insightful solutions to boost your success.
              </p>
              <div className="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+91 7410726107</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-xl-12">
                      <input
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Your Name"
                        style={{ height: '55px' }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Your Business"
                        style={{ height: '55px' }}
                        value={business}
                        onChange={(e) => setBusiness(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Service Interested"
                        style={{ height: '55px' }}
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control bg-light border-0"
                        rows="5"
                        placeholder="Message"
                        style={{ height: '55px' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-dark w-100 py-3"
                        type="submit"
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
